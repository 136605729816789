/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import axios from 'axios';

// @mui material components 
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import Header from "./components/Header";
import DataTableUserProfile from "examples/Tables/DataTableUserProfile";
import DataTableUserTransactions from "examples/Tables/DataTableUserTransactions";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PopupEditUser from "./components/PopupEditUser";
import PopupEditTransaction from "./components/PopupEditTransaction";
import PopupAddTransaction from "./components/PopupAddTransaction";
import MDAlert from "components/MDAlert";

import { useNavigate } from "react-router-dom";

function UserProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null)
  const [transactionsData, setTransactionsData] = useState(null)
  const [referralsData, setReferralsData] = useState(null)
  const [selectedTxData, setSelectedTxData] = useState(null)
  const [userUpdateAlert, setUserUpdateAlert] = useState(false)
  const [txUpdateAlert, setTxUpdateAlert] = useState(false)
  const [txDeleteAlert, setTxDeleteAlert] = useState(false)
  const [txAddAlert, setTxAddAlert] = useState(false)
  const [popupEditUserShow, setPopupEditUserShow] = useState(false)
  const [popupEditTransactionShow, setPopupEditTransactionShow] = useState(false)
  const [popupAddTransactionShow, setPopupAddTransactionShow] = useState(false)

  useEffect(async () => {
    await getUser()
    await getTransactions()
    await getReferrals()

    return () => {
      controller.abort()
    };
  }, [ id ])

  const handleTogglePopupEditUser = () => {
    setPopupEditUserShow(prevPopupEditUserShow => !prevPopupEditUserShow)
  }

  const handleTogglePopupEditTx = (transaction) => {
    setSelectedTxData(transaction);
    setPopupEditTransactionShow(prevPopupEditTransactionShow => !prevPopupEditTransactionShow)
  }
  
  const handleTableRowClick = (user) => {
    navigate("/user-profile/" + user);
  }

  const handleUserUpdateAlert = () => {
    setUserUpdateAlert(true)
    setTimeout(() => {
      setUserUpdateAlert(false)
    }, 3000)
  }

  const handleTxUpdateAlert = () => {
    setTxUpdateAlert(true)
    setTimeout(() => {
      setTxUpdateAlert(false)
    }, 3000)
  }

  const handleTxDeleteAlert = () => {
    setTxDeleteAlert(true)
    setTimeout(() => {
      setTxDeleteAlert(false)
    }, 3000)
  }

  const handleTxAddAlert = () => {
    setTxAddAlert(true)
    setTimeout(() => {
      setTxAddAlert(false)
    }, 3000)
  }

  const getUser = async () => {
    await axios({
      method: 'GET',
      url: `${global.config.APP_URL}/api/user/all?userId=` + id,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
      },
    }).then((res) => {
      setUserData(res.data)
    }).catch(err => {
      console.log(err)
      console.log('err')
    });
  }

  const updateUser = () => {
    getUser()
  }
  
  const updateTx = () => {
    getTransactions()
  }

  const getTransactions = async () => {
    await axios({
      method: 'GET',
      url: `${global.config.APP_URL}/api/transaction/all?userId=` + id,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
      },
    }).then((res) => {
      const data = extractTransactionsData(res.data.transactions);
      setTransactionsData(data);
    }).catch(err => {
      console.log(err)
      console.log('err')
    });
  }

  const getReferrals = async () => {
    await axios({
      method: 'GET',
      url: `${global.config.APP_URL}/api/user/referrals?userId=` + id,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
      },
    }).then((res) => {
      const data = extractReferralsData(res.data.referrals);
      setReferralsData(data);
    }).catch(err => {
      console.log(err)
      console.log('err')
    });
  }

  return (
    <DashboardLayout>

      <DashboardNavbar />

      {userUpdateAlert && <div className="alert-poup">
        <MDAlert mt={3} dismissible >User has been updated!</MDAlert>
      </div>
      }

      {txUpdateAlert && <div className="alert-poup">
        <MDAlert mt={3} dismissible >Transaction has been updated!</MDAlert>
      </div>
      }

      {txDeleteAlert && <div className="alert-poup">
        <MDAlert mt={3} dismissible >Transaction has been deleted!</MDAlert>
      </div>
      }

      {txAddAlert && <div className="alert-poup">
        <MDAlert mt={3} dismissible >Transaction has been added!</MDAlert>
      </div>
      }

      {userData &&
        <Header userData={userData} handleTogglePopupEditUser={handleTogglePopupEditUser} />
      }

      {transactionsData &&
        <MDBox my={3}>
          <Card>
            <div className="DataTableUsers">
              <DataTableUserTransactions
                handleTransactionClick={handleTogglePopupEditTx}
                handleAddClick={() => setPopupAddTransactionShow(true)}
                table={transactionsData}
                entriesPerPage={false}
                canSearch />
            </div>
          </Card>
        </MDBox>
      }

      {referralsData &&
        <MDBox my={3}>
          <Card>
            <div className="DataTableUsers">
              <DataTableUserProfile handleTableRowClick={handleTableRowClick} table={referralsData} entriesPerPage={false} canSearch />
            </div>
          </Card>
        </MDBox>
      }

      {popupEditUserShow && userData &&
        <PopupEditUser user={userData} handleUserUpdateAlert={handleUserUpdateAlert} updateUser={updateUser} handleTogglePopupEditUser={handleTogglePopupEditUser} />
      }

      {popupEditTransactionShow && selectedTxData &&
        <PopupEditTransaction
          tx={selectedTxData}
          handleTxUpdateAlert={handleTxUpdateAlert}
          handleTxDeleteAlert={handleTxDeleteAlert}
          updateTx={updateTx}
          handleTogglePopupEditTx={handleTogglePopupEditTx} />
      }

      {popupAddTransactionShow && 
        <PopupAddTransaction
          userId={id}
          handleTogglePopupAddTx={() => setPopupAddTransactionShow(false)}
          updateTx={updateTx}
          handleTxAddAlert={handleTxAddAlert} />
      }
      <Footer />
    </DashboardLayout>
  );
}

function extractTransactionsData(data) {
  return {
    columns: [
      {
        Header: "Type", accessor: "type", Cell: ({ value: type }) => (
          <DefaultCell value={type} />
        ),
      },
      {
        Header: "Currency", accessor: "currency", Cell: ({ value: currency }) => (
          <DefaultCell value={currency} />
        ),
      },
      {
        Header: "Value", accessor: "value", Cell: ({ value: value }) => (
          <DefaultCell value={value} />
        ),
      },
      {
        Header: "Date", accessor: "createdAt", Cell: ({ value: createdAt }) => (
          <DefaultCell value={createdAt} />
        ),
      },
      {
        Header: "Status", accessor: "status", Cell: ({ value: status }) => (
          <DefaultCell value={status} />
        ),
      },
    ],
  
    rows: data
  };
}

function extractReferralsData(data) {
  let constructedData = data.map(user => {
    return {
      id: user.id,
      email: user.email,
      name: user.firstName + " " + user.lastName
    }
  });

  return {
    columns: [
      {
        Header: "Name", accessor: "name", Cell: ({ value: name }) => (
          <DefaultCell value={name} />
        ),
      },
      {
        Header: "Email", accessor: "email", Cell: ({ value: email }) => (
          <DefaultCell value={email} />
        ),
      }
    ],
  
    rows: constructedData
  };
}

export default UserProfile;
