/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import BorderColorIcon from '@mui/icons-material/BorderColor';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Header({ userData, handleTogglePopupEditUser }) {


  return (
    <MDBox position="relative" mb={5}>

      <Card
        sx={{
          position: "relative",
          mt: 4,
          py: 3,
          px: 4,
        }}
      >
        <Grid  >
          <div className="profile-header">
            <div className="profile-header-top">
              <MDTypography variant="h3" className="title" fontWeight="medium">
                {userData.firstName + ' ' + userData.lastName}
              </MDTypography>
              <MDButton variant={'outlined'} color={'warning'}>Suspend Account</MDButton>
            </div>

            <MDTypography variant="h5" fontWeight="regular">
              Profile Information

              <MDButton onClick={handleTogglePopupEditUser} variant="text" color="dark">
                <BorderColorIcon />
              </MDButton>

            </MDTypography>

            <ul className="list-profile-items">
              <li>
                <strong>Full Name:</strong> {userData.firstName + ' ' + userData.lastName}
              </li>

              <li>
                <strong>Mobile: </strong> {userData.phone}
              </li>

              <li>
                <strong>Email: </strong> {userData.email}
              </li>

              <li>
                {userData.active &&
                  <>
                    <strong>Status: </strong>
                    <span className="userStatus active">Active</span>
                  </>
                }

                {!userData.active &&
                  <>
                    <strong>Status: </strong>
                    <span className="userStatus noactive"> Not Active</span>
                  </>
                }

              </li>
            </ul>
          </div>
        </Grid>
      </Card>
    </MDBox>
  );
}

export default Header;
