/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import axios from 'axios';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, Link } from 'react-router-dom';

import { useMaterialUIController, setToken } from "context";

// Authentication layout components
import LoginLayout from "layouts/authentication/components/LoginLayout";

import './login.css';

function Login() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  async function loginUser(credentials) { 
    const userData = await axios({
      method: 'post',
      url: `${global.config.AUTH_URL}/api/admin/login`,
      data: credentials,
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => { 
      return res.data
    }).catch(err => {
      console.log(err)
      console.log('login error')
      setLoginErr(true)
    });
    return userData;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = await loginUser({
      phone: username,
      passcode: password
    });
    if(data){
      setToken(dispatch, data.token);
      // setUserRole(dispatch, userRole);
      localStorage.setItem('token', data.token);
      navigate('/dashboard')
    }
  }

  return (
    <LoginLayout>
      <Card>
        <div className="login-card-head-logo">
          <img src="/logo.svg" alt="Return" />
        </div>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit} >
            <MDBox mb={2}>
              <MDInput error={loginErr} type="tel" label="Phone" fullWidth onChange={e => setUserName(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput error={loginErr} type="password" label="Password" onChange={e => setPassword(e.target.value)} fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>

            {loginErr && <div className="loginError" >Phone or Password not match</div>}

            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">

              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>

              </MDTypography>
            </MDBox>
          </MDBox>

        </MDBox>
      </Card>
    </LoginLayout>
  );
}


export default Login;
