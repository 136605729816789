/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// Material Dashboard 2 PRO React components 
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import markAsComplete from '../../../../../../api/markAsComplete';
import { useMaterialUIController, setToken } from "context";
import { shadows } from '@mui/system';

function DropDownCell({
  value
}) {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserDetailsClick = () => {
    const userId = value.userId;
    handleClose()
    navigate("/user-profile/" + userId);
  }

  const handleMarkAsComplete = () => {
    markAsComplete(value.transactionsId, '/api/transaction')
      .then((res) => {
        window.location.reload(false);
      }).catch(err => {
        console.log(err)
        console.log('err')
        setToken(dispatch, '');
        localStorage.removeItem('token');
      });
  }

  return (
    <div >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize={'medium'} />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="mui-dropdown-custom"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleUserDetailsClick}>User details</MenuItem>
        <MenuItem onClick={handleMarkAsComplete}>Mark as complete</MenuItem>
      </Menu>
    </div>
  );
}

export default DropDownCell;
