/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Dashboard from "layouts/dashboards/dashboard";
import Login from "layouts/authentication/sign-in/login";
import UserManagement from "layouts/dashboards/user-management";
import Transactions from "layouts/dashboards/transactions";
import UserProfile from "layouts/dashboards/user-profile";
 
// import Settings from "layouts/pages/account/settings";
 

// @mui icons
import Icon from "@mui/material/Icon"; 
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import GroupIcon from '@mui/icons-material/Group';
import OrderList from "layouts/ecommerce/orders/order-list";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Analytics",
    icon: <AutoGraphIcon fontSize="medium" />,
    key: "analytics",
    route: "/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customer Management",
    icon: <PersonIcon fontSize="medium" />,
    key: "customer",
    route: "/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logs",
    icon: <AssignmentIcon fontSize="medium"/>,
    key: "logs",
    route: "/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "External services availability and control",
    icon: <IntegrationInstructionsIcon fontSize="medium" />,
    key: "External",
    route: "/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "User Management",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    key: "User",
    route: "/user-management",
    component: <UserManagement />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Transactions",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    key: "Transactions",
    route: "/transactions",
    component: <Transactions />,
    noCollapse: true,
  },

  {
    type: "collapse-no",
    name: "User Profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    key: "Userprofile",
    route: "/user-profile/:id",
    component: <UserProfile />,
    noCollapse: true,
  },
  
  {
    type: "login",
    name: "login",
    icon: <GroupIcon fontSize="medium" />,
    key: "login",
    route: "/login",
    component: <Login />,
    noCollapse: true,
  }, 

  
  
];

export default routes;
