/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import MDButton from "components/MDButton";
import DashboardStatisticsCard from "components/DashboardStatisticsCard";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import getCoreAnalytics from "api/getCoreAnalytics";
import MDDatePicker from "components/MDDatePicker";
import Dialog from "@mui/material/Dialog";
import moment from "moment";

const filters = ["all", "yesterday", "daily", "weekly", "monthly", "custom"];

function Dashboard() {
  const [reportData, setReportData] = useState([]);
  const [activeButton, setActiveButton] = useState("all");
  const [openPopup, setOpenPopup] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getCoreAnalytics("/api/admin/dashboard")
      .then((res) => {
        const reportData = parseCoreAnalyticsResponse(res.data);

        setReportData(reportData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const parseCoreAnalyticsResponse = (data) => {
    return [
      {
        title: "Total users",
        count: data.totalUsers,
        fileName: "Total-Users.csv",
        endpoint: `/api/admin/export/total-users`,
      },
      {
        title: "Total active users",
        count: data.activeUsers,
        fileName: "Total-Active-Users.csv",
        endpoint: `/api/admin/export/active-users`,
      },
      {
        title: "Total deposits count",
        count: data.depositsCount,
        fileName: "Total-deposits-count.csv",
        endpoint: `/api/admin/export/total-deposits`,
      },
      {
        title: "Total deposits value",
        count: `$${data.depositsValue}`,
        fileName: "Total-deposits-value.csv",
        endpoint: `/api/admin/export/total-deposits`,
      },
      {
        title: "Total current deposits",
        count: `$${data.currentDeposits}`,
      },
      {
        title: "Total withdrawals count",
        count: data.withdrawalCount,
        fileName: "Total-withdrawals-count.csv",
        endpoint: `/api/admin/export/total-withdrawals`,
      },
      {
        title: "Total rewards",
        count: `$${data.rewardsValue}`,
        fileName: "Total-rewards.csv",
        endpoint: `/api/admin/export/total-rewards`,
      },
      {
        title: "Total value locked",
        count: `$${data.valueLocked}`,
      },
      {
        title: "Successful KYCs",
        count: data.successfulKycs,
        fileName: "Successful-KYCs.csv",
        endpoint: `/api/admin/export/successful-kycs`,
      },
      {
        title: "Failed KYCs",
        count: data.failedKycs,
        fileName: "Failed-KYCs.csv",
        endpoint: `/api/admin/export/failed-kycs`,
      },
      {
        title: "Non-verified KYCs",
        count: data.nonVerifiedKycs,
      },
      {
        title: "USD APY",
        count: `${data.usdApy}%`,
      },
      {
        title: "GBP APY",
        count: `${data.gbpApy}%`,
      },
      {
        title: "EUR APY",
        count: `${data.eurApy}%`,
      },
    ];
  };

  const handleClickOpen = (type) => {
    setOpenPopup(true);
    setActiveButton(type);
  };

  const handleClose = (type) => {
    setOpenPopup(false);
    setActiveButton(type);
  };
  const handleFilterStartDate = (date) => {
    const startDate = moment(new Date(date)).format("YYYY-MM-DD");

    setStartDate(startDate);
  };
  const handleFilterEndDate = (date) => {
    const endDate = moment(new Date(date)).format("YYYY-MM-DD");

    setEndDate(endDate);
  };

  const handleFilterDateButton = (type) => {
    getCoreAnalytics(
      `/api/admin/dashboard?filter=custom&startDate=${startDate}&endDate=${endDate}`
    )
      .then((res) => {
        const reportData = parseCoreAnalyticsResponse(res.data);

        setReportData(reportData);
        setOpenPopup(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setActiveButton(type);
  };

  const handleFilterButton = (type) => {
    getCoreAnalytics(`/api/admin/dashboard?filter=${type}`)
      .then((res) => {
        const reportData = parseCoreAnalyticsResponse(res.data);

        setReportData(reportData);
      })
      .catch((err) => {
        console.log(err);
      });
    setActiveButton(type);
  };

  const renderFilterButton = (filterName, index) => {
    return (
      <li key={`${filterName}-${index}`}>
        <MDButton
          size="medium"
          onClick={() => {
            filterName === "custom"
              ? handleClickOpen(filterName)
              : handleFilterButton(filterName);
          }}
          className={`btn-shadow ${
            activeButton === filterName ? "active" : ""
          }`}
        >
          {filterName}
        </MDButton>
      </li>
    );
  };

  const renderReportCard = (report, index) => {
    return (
      <Grid key={`${report.title}-${index}`} item xs={12} sm={4}>
        {report.endpoint ? (
          <DashboardStatisticsCard
            title={report.title}
            count={report.count}
            percentage={{
              color: "success",
              value: "+55%",
              label: "since last month",
            }}
            fileName={report.fileName}
            endpoint={`${report.endpoint}?filter=${activeButton}`}
          />
        ) : (
          <DefaultStatisticsCard
            title={report.title}
            count={report.count}
            percentage={{
              color: "success",
              value: "+55%",
              label: "since last month",
            }}
          />
        )}
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card sx={{ position: "relative", mt: 4, py: 2, px: 4 }}>
          <Grid
            container
            className="header-sub-dashboard"
            spacing={3}
            alignItems="center"
          >
            <div className="header-sub-dashboard-title">
              <div className="dashboard-row-head">
                <h2>Core Analytics</h2>
              </div>
            </div>
            <div className="header-sub-dashboard-menu">
              <AppBar position="static">
                <ul className="list-buttons">
                  {filters.map((filter, index) =>
                    renderFilterButton(filter, index)
                  )}
                </ul>
              </AppBar>
            </div>
          </Grid>
        </Card>
      </MDBox>

      <Dialog
        onClose={() => {
          handleClose("custom");
        }}
        open={openPopup}
      >
        <div className="dashboard-custom-date-popup">
          <div className="popup-head">
            <h4 className="popup-title">Choose specific date period</h4>
          </div>

          <div className="popup-body">
            <div className="cols">
              <div className="col-1of2">
                <small>From:</small>
                <MDDatePicker
                  onChange={(e) => {
                    handleFilterStartDate(e);
                  }}
                  input={{ placeholder: "Select a date" }}
                />
              </div>

              <div className="col-1of2">
                <small>To:</small>
                <MDDatePicker
                  onChange={(e) => {
                    handleFilterEndDate(e);
                  }}
                  input={{ placeholder: "Select a date" }}
                />
              </div>
            </div>

            <p className="popup-action">
              <MDButton
                disabled={startDate === "" || endDate === ""}
                color="info"
                onClick={() => {
                  handleFilterDateButton("custom");
                }}
              >
                Apply
              </MDButton>
            </p>
          </div>
        </div>
      </Dialog>

      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {reportData.map((report, index) => renderReportCard(report, index))}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
