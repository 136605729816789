let APP_URL;
console.log(`Current environment: ${process.env.REACT_APP_ENV}`);
if (process.env.REACT_APP_ENV === 'prod') {
    APP_URL = 'https://api.return.finance'
} else if (process.env.REACT_APP_ENV === 'staging') {
    APP_URL = 'https://staging-api.return.finance'
} else {
    APP_URL = 'http://localhost:4001'
}

let AUTH_URL;
if (process.env.REACT_APP_ENV === 'prod') {
    AUTH_URL = 'https://return-finance-auth-5x4454hjsa-lm.a.run.app'
} else if (process.env.REACT_APP_ENV === 'staging') {
    AUTH_URL = 'https://return-finance-auth-staging-5x4454hjsa-lm.a.run.app'
} else {
    AUTH_URL = 'http://localhost:4002'  
}

let PAYMENTS_SERVICE_URL;
if (process.env.REACT_APP_ENV === 'prod') {
    PAYMENTS_SERVICE_URL = 'https://return-finance-payments-service-5x4454hjsa-lm.a.run.app'
} else if (process.env.REACT_APP_ENV === 'staging') {
    PAYMENTS_SERVICE_URL = 'https://return-finance-payments-staging-5x4454hjsa-lm.a.run.app'
} else {
    PAYMENTS_SERVICE_URL = 'http://localhost:4003'  
}

export default global.config = {
    APP_URL,
    AUTH_URL,
    PAYMENTS_SERVICE_URL
};