
import { useState, useEffect, memo } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import axios from 'axios';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 PRO React components 

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import { ProtectedRoute } from "components/ProtectedRoute";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setToken } from "context";

import './App.css'

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { valid } from "chroma-js";

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    sidenavColor,
    layout,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    token
  } = controller;

  const navigate = useNavigate();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => { 
    if (token) {
      (async function () {
        const bodyParameters = {
          jwtToken: localStorage.getItem('token').split(' ')[1]
        };

        await axios({
          method: 'POST',
          url: `${global.config.AUTH_URL}/api/auth/verify`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyParameters
        }).then((res) => {
          if (res.status === 200) {
            return
          } else {
            setToken(dispatch, '');
            // setUserRole(dispatch, userRole);
            localStorage.removeItem('token');
          }
        }).catch(err => {
          console.log(err)
          console.log('err');
          setToken(dispatch, '');
          // setUserRole(dispatch, userRole);
          localStorage.removeItem('token');
        });
      })()
    } 
  }, [])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, i) => {
      if (route.route) {

        if (route.route == "/login" && token) {
          return <Route key={i} path="/dashboard" element={<Navigate to="/dashboard" />} />
        }

        if (route.route == "/login") {
          return <Route key={i} exact path={route.route} element={route.component} />;
        }

        return <Route key={i} exact path={route.route} element={<ProtectedRoute>{route.component}</ProtectedRoute>} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" &&
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard PRO"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </>
      }

      {!(layout === "dashboard") &&
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      }

    </ThemeProvider>
  )
}

export default memo(App)