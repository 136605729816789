/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

import axios from 'axios';

// @mui material components 
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import PopupConfirm from 'components/PopupConfirm';

function PopupEditTransaction({
    tx,
    handleTogglePopupEditTx,
    updateTx,
    handleTxUpdateAlert,
    handleTxDeleteAlert
}) {
    const [loginErr, setLoginErr] = useState(false);
    const [promptDelete, setPromptDelete] = useState(false);
    const [currency, setCurrency] = useState(tx.currency);
    const [type, setType] = useState(tx.type);
    const [value, setValue] = useState(tx.value);
    const [txStatus, setTxStatus] = useState(tx.status);
 
    async function editTx(tx) {
        const txData = await axios({
            method: 'PATCH',
            url: `${global.config.APP_URL}/api/transaction`,
            data: tx,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
            }
        }).then((res) => {
            if (res.status === 200) {
                handleTogglePopupEditTx()
                updateTx()
                handleTxUpdateAlert()
            }
            return res
        }).catch(err => {
            console.log(' error')
            console.log(err)
            setLoginErr(true)
        });
        return txData;
    }

    async function deleteTx() {
        const txData = await axios({
            method: 'DELETE',
            url: `${global.config.APP_URL}/api/transaction?txId=` + tx.id,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
            }
        }).then((res) => {
            if (res.status === 200) {
                handleTogglePopupEditTx()
                updateTx()
                handleTxDeleteAlert()
            }
            return res
        }).catch(err => {
            console.log('tx delete error')
            console.log(err)
            setLoginErr(true)
        });
        return txData;
    }

    const handleTxStatusChange = (e) => {
        setTxStatus(e.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = await editTx({
            "id": tx.id,
            "currency": currency,
            "type": type,
            "value": value,
            "status": txStatus
        });
    }

    return (
        <div className="popupEditEntity" >
            <div className="popupEditEntity-content">
                <button className="closePopup" onClick={handleTogglePopupEditTx} >
                    <CloseIcon />
                </button>
                <div className="popupEditEntity-inner">
                    <MDBox component="form" role="form" onSubmit={handleSubmit} >
                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="text" disabled={true} defaultValue={tx.type || ''} label="Type" fullWidth  />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="text" disabled={true} defaultValue={tx.currency || ''} label="Currency" fullWidth onChange={e => setCurrency(e.target.value)} />
                        </MDBox>

                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="text" defaultValue={tx.value || ''} label="Value" onChange={e => setValue(e.target.value)} fullWidth />
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>
                                    Status
                                </p>

                                <Select
                                    onChange={handleTxStatusChange}
                                    options={[
                                        { value: 'pending', label: 'pending' },
                                        { value: 'completed', label: 'completed' },
                                        { value: 'rejected', label: 'rejected' }
                                    ]}
                                    defaultValue={{ value: txStatus, label: txStatus }}
                                />
                            </div>
                        </MDBox>

                        {loginErr && <div className="loginError" >Fields not match</div>}

                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Edit
                            </MDButton>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                            <MDButton onClick={() => setPromptDelete(true)} variant="gradient" color="error" fullWidth>
                                Delete
                            </MDButton>
                        </MDBox>
                    </MDBox>

                    { promptDelete &&
                        <PopupConfirm
                            promptText="Are you sure you want to delete this transaction?"
                            actionConfirm={deleteTx}
                            actionReject={() => setPromptDelete(false)}
                        />
                    }

                </div>
            </div>
        </div>
    );
}

export default PopupEditTransaction;
