/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com) 
*/

import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import DropDownCell from "layouts/ecommerce/orders/order-list/components/DropDownCell";

 
const initDataTableData = {
  columns: [
    {
      Header: "user",
      accessor: "user",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "value",
      accessor: "value",
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "currency",
      accessor: "currency",
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "date",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "account",
      accessor: "account",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    { Header: "Actions", accessor: "actions", Cell: ({ value }) => <DropDownCell value={value} /> },
  ],

  rows: [
    {
      user: 'dasada',
      id: "#10421",
      date: "1 Nov, 10:20 AM",
      value: "140,20",
      currency: 'USD',
      account: '1234567890'
    },
    {
      user: 'dasada',
      id: "#10422",
      date: "1 Nov, 10:53 AM",
      value: "42,00",
      currency: 'USD',
      account: '1234567890'
    },
    {
      user: 'dasada',
      id: "#10423",
      date: "1 Nov, 11:13 AM",
      value: "250",
      currency: 'USDC',
      account: '0x0987654321'
    },
    {
      user: 'dasada',
      id: "#10424",
      date: "1 Nov, 12:20 PM",
      value: "1900",
      currency: 'USDC',
      account: '0x0987654321'
    },

  ],
};

export default initDataTableData;
