import { useState, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";

import getExportData from '../../api/getExportData';

function ExportButton({
    fileName,
    buttonStyle,
    closeDropdown,
    endpoint
}) {
    const [comingData, setComingData] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputElement = useRef();
    let style = {};

    if (buttonStyle == 'inMenu') {
        style = {
            backgroundColor: 'rgba(0,0,0,0)',
            border: 0,
            borderRadius: 0
        }
    }

    const hendleClick = () => {
        getData();
    }

    const getData = () => {

        setLoading(true);
        getExportData(endpoint)
            .then((data) => { 
                const filterData = data.data.map(item => {

                    if (item.balance) {
                        Object.keys(item.balance).forEach(function (key, index) {
                            const keyName = 'Balance ' + key
                            item[keyName] = item.balance[key]
                        });
                        const newItem = Object.keys(item)
                            .filter((key) => !(key == 'balance'))
                            .reduce((obj, key) => {
                                return Object.assign(obj, {
                                    [key]: item[key]
                                });
                            }, {});

                        return newItem
                    }

                    return item
                })
                setComingData(filterData)

                setLoading(false);
                inputElement.current.link.click()

            }).catch((err) => {
                setLoading(false);
                console.log(err)
            });
    }

    return (
        <div className="export-button-wrapper">
            <button className='button-export-inner' style={style} onClick={hendleClick}>
                {loading ? <div className="loader"><span></span></div> : <div>Export CSV</div>}
            </button>

            <CSVLink
                onClick={() => {
                    closeDropdown()
                }}
                ref={inputElement}
                data={comingData}
                filename={fileName}
            >
                Downlowad
            </CSVLink>
        </div>
    )
}

export default ExportButton;
