/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function PopupConfirm({
    promptText,
    actionConfirm,
    actionReject
}) {
    return (
        <div>
            <MDBox>
                <MDTypography variant="body2" color="text">
                    {promptText}
                </MDTypography>
            </MDBox>

            <MDButton onClick={actionConfirm} variant="gradient" color="error">
                Delete
            </MDButton>

            <MDButton onClick={actionReject} variant="gradient" color="secondary">
                Cancel
            </MDButton>
        </div>
    );
}

export default PopupConfirm;
