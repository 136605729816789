/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples 
import PageLayout from "examples/LayoutContainers/PageLayout";


// Authentication pages components 

function LoginLayout({ children }) {
  return (
    <PageLayout>
      <div className="login-page-outer">
        <div className="login-bg-top">
          <img src="/login-bg-top.svg" alt="#" />  
        </div>

        <div className="login-bg-bottom">
          <img src="/login-bg-bottom.svg" alt="#" />  
        </div>

        <div className="login-page">

          <div className="login-page-inner">
            <div className="login-page-content">
              <div className="login-page-head">
                <h2 className="login-page-title">
                  Welcome!
                </h2>

                <p>
                  Sign into your Return Account, if you have issue please contact your admin.
                </p>
              </div>

              <div className="login-page-body">
                {children}
              </div>
            </div>
          </div>

          <div className="login-page-footer">
            <p>
              Copyright © 2022 Return Network, LLC. All rights reserved
            </p>
          </div>
        </div>
      </div>


    </PageLayout>
  );
}

// Typechecking props for the LoginLayout
LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
