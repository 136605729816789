import axios from 'axios';

const getCoreAnalytics = (endpoint) => {
    return axios({
        method: 'GET',
        url: `${global.config.APP_URL}${endpoint}`,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
        },
      })
}

export default getCoreAnalytics;