/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 
import MDBox from "components/MDBox";
import Button from '@mui/material/Button';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import CloseIcon from '@mui/icons-material/Close';

function PopupConfirmDeposit({
    promptText,
    actionConfirm,
    actionReject,
}) {
    return (
        <div className="popupEditEntity" >
            <div className="popupEditEntity-content">
                <MDTypography variant="h2" color="dark">
                    {promptText}
                </MDTypography>
                <button className="closePopup" onClick={actionReject} >
                    <CloseIcon />
                </button>
                <div className="popupEditEntity-inner">
                    <MDBox component="form" role="form" onSubmit={actionConfirm} >
                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Confirm
                            </MDButton>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                            <Button variant="gradient" color="white" onClick={actionReject} fullWidth>
                                Cancel
                            </Button>
                        </MDBox>
                    </MDBox>
                </div>
            </div>
        </div>
    );
}

export default PopupConfirmDeposit;
