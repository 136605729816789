import axios from 'axios';

const getPendingBankWithdrawals = () => {
    return axios({
        method: 'GET',
        url: `${global.config.APP_URL}/api/transaction/pending-withdrawals`,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
        }
    })
}

const getPendingBankDeposits = () => {
    return axios({
        method: 'GET',
        url: `${global.config.APP_URL}/api/transaction/pending-bank-deposits`,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
        }
    })
}

const confirmPendingDeposit = (transactionId) => {
    return axios({
        method: 'PATCH',
        url: `${global.config.PAYMENTS_SERVICE_URL}/api/orders/${transactionId}/execute`,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
        }
    })
}

export {
    getPendingBankWithdrawals,
    getPendingBankDeposits,
    confirmPendingDeposit
};
