import axios from 'axios';

const markAsComplete = (id, endpoint) => {
    console.log({ "id": id, "status": "completed" })
    return axios({
        method: 'PATCH',
        url: `${global.config.APP_URL}${endpoint}`,
        data: { "id": id, "status": "completed" },
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
        },
    })
}

export default markAsComplete;
