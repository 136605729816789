import { Navigate } from "react-router-dom"; 
import { useMaterialUIController  } from "context";

export const ProtectedRoute = ({ children }) => {
    const [controller] = useMaterialUIController();
    const { token } = controller;  

    if (!token) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return children;
  };