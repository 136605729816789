
import { useState } from 'react';

import ExportButton from 'components/ExportButton';
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import Menu from "@mui/material/Menu";
import MoreVertIcon from '@mui/icons-material/MoreVert';

function DashboardStatisticsCard({
    count,
    title,
    percentage,
    fileName,
    endpoint
}) {

    const [dropdown, setDropdown] = useState(null);

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => { setDropdown(null); };

    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(state)}
            className="mui-dropdown-custom"
            onClose={close}
        >
            <ExportButton closeDropdown={() => { setDropdown(null) }} buttonStyle={'inMenu'} fileName={fileName} endpoint={endpoint} />
        </Menu>
    );

    return (

        <DefaultStatisticsCard
            title={title}
            count={count}
            percentage={percentage}
            dropdown={{
                action: openDropdown,
                menu: renderMenu(dropdown, closeDropdown),
                value: <MoreVertIcon fontSize="small" />,
            }}
        />


    )
}

export default DashboardStatisticsCard;
