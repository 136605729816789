/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components 
import Header from "./components/Header";
import Card from "@mui/material/Card";
import DataTableUsers from "examples/Tables/DataTableUsers";
import DataTableNonRewardedReferrals from "./components/DataTableNonRewardedReferrals";
import MDBox from "components/MDBox";

import initDataTableData from "./data/dataTableData";
import initDataNonRewardedReferralsTableData from "./data/dataTableNonRewardedReferralsData";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMaterialUIController, setToken } from "context";

import { useNavigate } from "react-router-dom";

import axios from 'axios';

function UserManagement() {
  const navigate = useNavigate();
  const [dataTableData, setDataTableData] = useState({})
  const [nonRewardedReferralsData, setNonRewardedReferralsData] = useState({})
  const [controller, dispatch] = useMaterialUIController();

  const fillDataRows = (data) => {
    const constructData = data.map(user => {
      return {
        id: user.id,
        email: user.email,
        role: user.role,
        createdAt: new Date(user.createdAt).toLocaleDateString("en-US"),
        updatedAt: new Date(user.updatedAt).toLocaleDateString("en-US"),
        name: user.firstName + " " + user.lastName,
        status: user.active,
        kycVerification: user.kycVerification,
        referenceCode: user.referenceCode
      }
    })
    setDataTableData({ columns: initDataTableData.columns, rows: constructData })
  }

  const fillNonRewardedReferralsRows = (data) => {
    const constructData = data.map(user => {
      return {
        id: user.id,
        email: user.useremail,
        name: user.username,
        referrerEmail: user.referreremail,
        referrerName: user.referrername
      }
    });
    setNonRewardedReferralsData({ columns: initDataNonRewardedReferralsTableData.columns, rows: constructData })
  }

  const handleTableRowClick = (user) => {
    navigate("/user-profile/" + user);
  }

  const handleNonRewardedReferralsTableRowClick = (user) => {
    navigate("/user-profile/" + user);
  }

  useEffect(async () => {
    await getUsers();
    await getNonRewardedReferrals();
  }, [])

  const getUsers = async () => {
    await axios({
      method: 'GET',
      url: `${global.config.APP_URL}/api/user/all`,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
      },
    }).then((res) => {
      fillDataRows(res.data)
    }).catch(err => {
      console.log(err)
      console.log('err')
      setToken(dispatch, '');
      localStorage.removeItem('token');
    });
  }

  const getNonRewardedReferrals = async () => {
    await axios({
      method: 'GET',
      url: `${global.config.APP_URL}/api/user/missing-referral-rewards`,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
      },
    }).then((res) => {
      fillNonRewardedReferralsRows(res.data);
    }).catch(err => {
      console.log(err)
      console.log('err')
      setToken(dispatch, '');
      localStorage.removeItem('token');
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {dataTableData.rows ?
        <Header users={dataTableData.rows.length} />
        :
        <Header />
      }

      <MDBox my={3}>
        <Card>
          {dataTableData.rows ?
            <div className="DataTableUsers">
              <DataTableUsers handleTableRowClick={handleTableRowClick} table={dataTableData} entriesPerPage={false} canSearch />
            </div>
            :
            <Card sx={{ p: 4 }}>
              <div className="section-loader">
                <div className="loader"><span></span></div>
              </div>
            </Card>
          } 
        </Card>
      </MDBox>

      <MDBox my={3}>
        <Card>
          {nonRewardedReferralsData.rows &&
            <div className="DataTableUsers">
              <DataTableNonRewardedReferrals handleTableRowClick={handleNonRewardedReferralsTableRowClick} table={nonRewardedReferralsData} entriesPerPage={false} canSearch />
            </div>
          }
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserManagement;
