/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import ButtonCell from "layouts/ecommerce/orders/order-list/components/ButtonCell";


const initDataTableData = {
  columns: [
    { Header: "name", accessor: "name", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Reference code", accessor: "referenceCode", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Account Type", accessor: "role", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "KYC status", accessor: "kycVerification", Cell: ({ value }) => {
        let status;
        if (value === "none") {
          status = <StatusCell color="secondary" status="Not Started" />;
        } else if (value === "initiated") {
          status = <StatusCell color="light" status="Started" />;
        } else if (value === "pending") {
          status = <StatusCell color="info" status="Submitted" />;
        } else if (value === "completed") {
          status = <StatusCell color="success" status="Verified" />;
        } else if (value === "failed") {
          status = <StatusCell color="warning" status="Failed" />;
        } else if (value === "rejected") {
          status = <StatusCell color="error" status="Rejected" />;
        } else {
          status = <StatusCell color="secondary" status="Not Started" />;
        }
        return status;
      },
    },
    {
      Header: "status", accessor: "status", Cell: ({ value }) => {
        let status;
        if (value === true) {
          status = <StatusCell color="success" status="Active" />;
        } else {
          status = <StatusCell color="error" status="Inactive" />;
        }
        return status;
      },
    },
    { Header: "Registered on", accessor: "createdAt", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Updated At", accessor: "updatedAt", Cell: ({ value }) => <DefaultCell value={value} /> },

    { Header: "Actions", accessor: "actions", Cell: ({ value }) => <ButtonCell value={<MoreVertIcon fontSize={'medium'} />} /> },
  ],

  rows: []
};

export default initDataTableData;
