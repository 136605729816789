/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
import { useMaterialUIController, setToken } from "context";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PopupConfirmDeposit from './components/PopupConfirmDeposit';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import PendingBankDepositMenuCell from "layouts/ecommerce/orders/order-list/components/PendingBankDepositMenuCell";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import DataTableTransactions from "examples/Tables/DataTableTransactions";
import DataTablePendingDeposits from "examples/Tables/DataTablePendingDeposits";

// Data
import initDataTableData from "./data/dataPendingWithdrawals.js";

// API
import { getPendingBankWithdrawals, getPendingBankDeposits, confirmPendingDeposit } from '../../../api/transactions';

function Transactions() {
  const initPendingBankDepositsTableData = {
    actions: {
        confirm: (transactionId) => {
            setSelectedTxId(transactionId);
            setPromptConfirm(true);
        }
    },
    columns: [
        { Header: "user", accessor: "user", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "value", accessor: "value", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "currency", accessor: "currency", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "date", accessor: "date", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ value }) => <PendingBankDepositMenuCell value={value} actions={initPendingBankDepositsTableData.actions} />
        },
    ],
    rows: []
  };
  const [error, setError] = useState(false);
  const [dispatch] = useMaterialUIController();
  const [selectedTxId, setSelectedTxId] = useState(null);
  const [promptConfirm, setPromptConfirm] = useState(false);
  const [dataTableData, setDataTableData] = useState({ columns: initDataTableData.columns, rows: [] });
  const [pendingBankDepositsTableData, setPendingBankDepositsTableData] = useState({ actions: {}, columns: initPendingBankDepositsTableData.columns, rows: [] });

  const fillDataRows = (data) => { 
    const constructData = data.map(withdrawals => {
      let withdrawal = {
        id: withdrawals.userId,
        user: withdrawals.accountHolderName,
        value: withdrawals.value,
        currency: withdrawals.currency,
        date: new Date(withdrawals.createdAt).toLocaleDateString("en-US"),
        actions: { userId: withdrawals.userId, transactionsId: withdrawals.id },
      };

      if (withdrawals.iban) {
        withdrawal.account = withdrawals.iban;
      } else {
        withdrawal.account = withdrawals.withdrawalAddress;
      }

      return withdrawal
    })
    setDataTableData({ columns: initDataTableData.columns, rows: constructData })
  }

  const fillPendingBankDepositsRows = (data) => {
    const constructData = data.map(deposits => {
      return {
        id: deposits.userId,
        user: deposits.accountHolderName,
        value: deposits.value,
        currency: deposits.currency,
        date: new Date(deposits.createdAt).toLocaleDateString("en-US"),
        actions: { userId: deposits.userId, transactionsId: deposits.id },
      }
    })
    setPendingBankDepositsTableData({ actions: initPendingBankDepositsTableData.actions, columns: initPendingBankDepositsTableData.columns, rows: constructData })
  }

  useEffect(async () => {
    await getPendingWithdrawals();
    await getPendingDeposits();
  }, [])

  const getPendingWithdrawals = async () => {
    getPendingBankWithdrawals()
      .then((res) => {
        fillDataRows(res.data)
      }).catch(err => {
        console.log(err) 
        setToken(dispatch, '');
        localStorage.removeItem('token');
      });
  }

  const getPendingDeposits = async () => {
    getPendingBankDeposits()
      .then((res) => {
        fillPendingBankDepositsRows(res.data)
      }).catch(err => {
        console.log(err) 
        setToken(dispatch, '');
        localStorage.removeItem('token');
      });
  }

  const confirmDeposit = async () => {
    setPromptConfirm(false);
    confirmPendingDeposit(selectedTxId)
      .then((res) => {
        window.location.reload(false);
      }).catch(err => {
        console.log('err');
        console.log(err);
        setError(true);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      { error && <div className="generalError">Failed to confirm deposit.</div> }
      { promptConfirm &&
        <PopupConfirmDeposit
          promptText="Are you sure you want to confirm this deposit and execute it?"
          actionConfirm={confirmDeposit}
          actionReject={() => setPromptConfirm(false)}
        />
      }
      <MDBox my={3}>
        <Card>
          <MDBox height="100%" px={4} pt={2} mt={0.5} lineHeight={1}>
            <MDTypography variant="h3" fontWeight="regular">
              Pending Bank Deposits
            </MDTypography>
          </MDBox>
          <div className="DataTablePendingDeposits">
            <DataTablePendingDeposits
              table={pendingBankDepositsTableData}
              entriesPerPage={false}
              canSearch
            />
          </div>
        </Card>
      </MDBox>
      <MDBox my={3}>
        <Card>
          <MDBox height="100%" px={4} pt={2} mt={0.5} lineHeight={1}>
            <MDTypography variant="h3" fontWeight="regular">
              Pending Withdrawals
            </MDTypography>
          </MDBox>
          <div className="DataTableUsers">
            <DataTableTransactions
              table={dataTableData}
              entriesPerPage={false}
              canSearch
            />
          </div>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Transactions;
