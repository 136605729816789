/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";

const initDataNonRewardedReferralsTableData = {
  columns: [
    { Header: "Referral Name", accessor: "name", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Referral Email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Referrer Name", accessor: "referrerName", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Referrer Email", accessor: "referrerEmail", Cell: ({ value }) => <DefaultCell value={value} /> }
    // { Header: "Actions", accessor: "actions", Cell: ({ value }) => <ButtonCell value={<MoreVertIcon fontSize={'medium'} />} /> },
  ],

  rows: []
};

export default initDataNonRewardedReferralsTableData;
