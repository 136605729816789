/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

import axios from 'axios';

// @mui material components 
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CloseIcon from '@mui/icons-material/Close';
import Switch from "@mui/material/Switch";
import Select from 'react-select';

function PopupEditUser({
    user,
    handleTogglePopupEditUser,
    updateUser,
    handleUserUpdateAlert
}) {
    const [loginErr, setLoginErr] = useState(false);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [userRole, setuserRole] = useState(user.role);
    const [active, setActive] = useState(user.active); 
    const [userKycVerification, setUserKycVerification] = useState(user.kycVerification); 
 
    async function editUser(user) {
        const userData = await axios({
            method: 'PATCH',
            url: `${global.config.APP_URL}/api/user`,
            data: user,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
            }
        }).then((res) => {
            if (res.status === 200) {
                handleTogglePopupEditUser()
                updateUser()
                handleUserUpdateAlert()
            }
            return res
        }).catch(err => {
            console.log(err)
            setLoginErr(true)
            console.log('login error')
        });
        return userData;
    }

    const handleUserRoleChange = (e) => {
        setuserRole(e.value)
    }
    const handleUserVerification = (e) => {
        setUserKycVerification(e.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = await editUser({
            "id": user.id,
            "role": userRole,
            "kycVerification": userKycVerification,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "active": active
        });
    }

    return (
        <div className="popupEditEntity" >
            <div className="popupEditEntity-content">
                <button className="closePopup" onClick={handleTogglePopupEditUser} >
                    <CloseIcon />
                </button>
                <div className="popupEditEntity-inner">
                    <MDBox component="form" role="form" onSubmit={handleSubmit} >
                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="text" defaultValue={user.firstName || ''} label="First Name" fullWidth onChange={e => setFirstName(e.target.value)} />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="text" defaultValue={user.lastName || ''} label="Last Name" fullWidth onChange={e => setLastName(e.target.value)} />
                        </MDBox>

                        <MDBox mb={2}>
                            <MDInput error={loginErr} type="email" defaultValue={user.email || ''} label="Email" onChange={e => setEmail(e.target.value)} fullWidth />
                        </MDBox>

                        <MDBox mt={2}>
                            <p> <small> <small>User Status</small> </small> </p>
                            <Switch checked={active} onChange={() => setActive(!active)} />
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>
                                    Role
                                </p>

                                <Select
                                    onChange={handleUserRoleChange}
                                    options={[
                                        { value: 'User', label: 'User' },
                                        { value: 'Admin', label: 'Admin' },
                                    ]}
                                    defaultValue={{ value: userRole, label: userRole }}
                                />
                            </div>
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>
                                    KYC status
                                </p>

                                <Select
                                    onChange={handleUserVerification}
                                    options={[
                                        { value: 'none', label: 'none' },
                                        { value: 'initiated', label: 'initiated' },
                                        { value: 'pending', label: 'pending' },
                                        { value: 'completed', label: 'completed' },
                                        { value: 'failed', label: 'failed' },
                                        { value: 'rejected', label: 'rejected' },
                                    ]}
                                    defaultValue={{ value: userKycVerification, label: userKycVerification }}
                                />
                            </div>
                        </MDBox>

                        {loginErr && <div className="loginError" >Fields not match</div>}

                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Edit
                            </MDButton>
                        </MDBox>
                    </MDBox>

                </div>
            </div>
        </div>
    );
}

export default PopupEditUser;
