/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

import axios from 'axios';

// @mui material components 
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from 'components/MDTypography';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';

function PopupAddTransaction({
    userId,
    handleTogglePopupAddTx,
    updateTx,
    handleTxAddAlert
}) {
    const [error, setError] = useState(false);
    const [currency, setCurrency] = useState('USD');
    const [type, setType] = useState('deposit');
    const [value, setValue] = useState('0.0');
    const [txStatus, setTxStatus] = useState('pending');
 
    async function addTx(tx) {
        const txData = await axios({
            method: 'POST',
            url: `${global.config.APP_URL}/api/transaction?userId=` + userId,
            data: tx,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "JWT " + localStorage.getItem('token').split(' ')[1]
            }
        }).then((res) => {
            if (res.status === 201) {
                handleTogglePopupAddTx();
                updateTx();
                handleTxAddAlert();
            }
            return res;
        }).catch(err => {
            console.log('Error while adding transaction');
            console.log(err);
            setError(true);
        });
        return txData;
    }
    
    const handleTxCurrencyChange = (e) => {
        setCurrency(e.value);
    }

    const handleTxTypeChange = (e) => {
        setType(e.value);
    }

    const handleTxStatusChange = (e) => {
        setTxStatus(e.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = await addTx({
            "currency": currency,
            "type": type,
            "value": value,
            "status": txStatus
        });
    }

    return (
        <div className="popupEditEntity" >
            <div className="popupEditEntity-content">
                <MDTypography variant="h2" color="dark">
                    Add new transaction
                </MDTypography>
                <button className="closePopup" onClick={handleTogglePopupAddTx} >
                    <CloseIcon />
                </button>
                <div className="popupEditEntity-inner">
                    <MDBox component="form" role="form" onSubmit={handleSubmit} >
                        <MDBox mt={2}>
                            <MDInput error={error} type="text" defaultValue={value} label="Value" onChange={e => setValue(e.target.value)} fullWidth />
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>Currency</p>

                                <Select
                                    onChange={handleTxCurrencyChange}
                                    options={[
                                        { value: 'EUR', label: 'EUR' },
                                        { value: 'GBP', label: 'GBP' },
                                        { value: 'USD', label: 'USD' }
                                    ]}
                                    defaultValue={{ value: currency, label: currency }}
                                />
                            </div>
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>Type</p>

                                <Select
                                    onChange={handleTxTypeChange}
                                    options={[
                                        { value: 'deposit', label: 'deposit' },
                                        { value: 'withdraw', label: 'withdraw' },
                                        { value: 'reward', label: 'reward' },
                                        { value: 'referral deposit', label: 'referral deposit' },
                                        { value: 'bonus', label: 'bonus' }
                                    ]}
                                    defaultValue={{ value: type, label: type }}
                                />
                            </div>
                        </MDBox>

                        <MDBox mt={2}>
                            <div className="custom-select">
                                <p>Status</p>

                                <Select
                                    onChange={handleTxStatusChange}
                                    options={[
                                        { value: 'pending', label: 'pending' },
                                        { value: 'completed', label: 'completed' },
                                        { value: 'rejected', label: 'rejected' }
                                    ]}
                                    defaultValue={{ value: txStatus, label: txStatus }}
                                />
                            </div>
                        </MDBox>

                        {error && <div className="loginError" >Failed to create transaction.</div>}

                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Add
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </div>
            </div>
        </div>
    );
}

export default PopupAddTransaction;
